/* eslint-disable @typescript-eslint/ban-types */
import {isBeforeDate} from '@/core';

import {Day, Menu, Picto, Service} from '../interfaces';
import MenuService from '../services/MenuService';

export const actions = {
  getListMenus: async ({commit}: {commit: Function}, id: string): Promise<void> => {
    commit('menu/SET_LOADING_MENU', true, {root: true});
    const today = new Date();
    today.setUTCHours(0, 0, 0, 0);
    MenuService.getListMenus(id)
      .then((response) => {
        const menus = response.data.filter(
          (a: Menu) => new Date(a.date) >= today
        );
        commit('menu/SET_MENUS', menus, {root: true});
        commit('menu/SET_DAYS', null, {root: true});
        commit('menu/SET_DAY', null, {root: true});
        if (menus.length > 0) {
          actions.getCurrentMenu(
            {commit},
            menus.sort((a: Menu, b: Menu) => (isBeforeDate(a.date, b.date) ? -1 : 1))[0]._id
          );
        }
      })
      .catch(() => {
        commit('menu/SET_ERROR', true, {root: true});
      })
      .finally(() => commit('menu/SET_LOADING_MENU', false, {root: true}));
  },
  getCurrentMenu: async ({commit}: {commit: Function}, id: string): Promise<void> => {
    MenuService.getMenu(id)
      .then((response) => {
        commit('menu/SET_CURRENT_MENU', response.data, {root: true});
      })
      .catch(() => {
        commit('menu/SET_ERROR', true, {root: true});
      })
  },
  updateSelectedProductTypeName: (
    {commit}: {commit: Function},
    selectedProductTypeName: string
  ): void => {
    commit('SET_SELECTED_PRODUCT_TYPE_NAME', selectedProductTypeName);
  },
  updateCurrentProductTypes: ({commit}: {commit: Function}, productTypes: Picto[]): void => {
    commit('SET_CURRENT_PRODUCT_TYPES', productTypes);
  },
  updateDay: ({commit}: {commit: Function}, day: Day): void => {
    commit('SET_DAY', day);
  },
  updateService: ({commit}: {commit: Function}, service: Service): void => {
    commit('SET_SERVICE', service);
  },

  resetError: ({commit}: {commit: Function}): void => {
    commit('SET_ERROR', false);
  },
};
