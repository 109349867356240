
import {Component, Vue, Watch} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';

import SelectorSite from '@/site/components/SelectorSite.vue';
import {Links, ServiceLinks, Site} from '@/site/interfaces';

import {mdiSvg, nameRoutes} from '../tools';

@Component({components: {SelectorSite}})
export default class BaseService extends Vue {
  @Getter('links', {namespace: 'site'}) links!: Links;

  @Getter('hasArticles', {namespace: 'article'}) hasArticles!: boolean;

  @Getter('isOpenSelector', {namespace: 'site'}) isOpenSelector!: boolean;

  @Getter('isDemo', {namespace: 'site'}) isDemo!: boolean;

  @Getter('siteList', {namespace: 'site'}) siteList!: Site[];

  @Getter('customerId', {namespace: 'site'}) customerId!: string;

  @Getter('enableAffluence', {namespace: 'site'}) enableAffluence!: boolean;

  @Getter('name', {namespace: 'site'}) name!: string;

  // @Getter('isBookingActivated', {namespace: 'site'}) isBookingActivated!: boolean;

  @Getter('enableContact', {namespace: 'site'}) enableContact!: boolean;

  @Getter('accessToken', {namespace: 'user'}) accessToken!: string;

  @Getter('email', {namespace: 'user'}) email!: string;

  @Getter('balance', {namespace: 'user'}) balance!: number;

  @Getter('isNewUser', {namespace: 'user'}) isNewUser!: boolean;

  @Getter('hasBookingConfig', {namespace: 'booking'}) hasBookingConfig!: boolean;

  @Getter('enableBooking', {namespace: 'site'}) enableBooking!: boolean;

  @Action('updateOpenSelector', {namespace: 'site'}) updateOpenSelector!: (
    isOpen: boolean
  ) => void;

  @Action('logout', {namespace: 'user'}) logout!: (token: string) => void;

  affluenceDialog = false;

  svgIcons = mdiSvg;

  routes = nameRoutes;

  services: ServiceLinks[] = [];

  onlineServices: ServiceLinks[] = [];

  menuDescription = 'Consulter la carte du restaurant';

  newsDescription = 'Voir les actus de votre restaurant';

  accountDescription = 'Accéder à votre compte personnel';

  clickCollectDescription = 'Commande en ligne';

  roomServiceDescription = 'Room Service';

  menuIcon = this.svgIcons.silverware;

  newsIcon = this.svgIcons.newspaper;

  accountIcon = this.svgIcons.account;

  clickCollectIcon = this.svgIcons.shopping;

  roomServiceIcon = this.svgIcons.roomService;

  contactIcon = this.svgIcons.pencil;

  bottomNavServices = false;

  bottomNavRoomService = false;

  pathRoomServiceFile = '';

  pathOrderFile = '';

  loginDialog = false;

  @Watch('$store.state.site.links')
  setLinks(): void {
    if (this.links) {
      this.onlineServices = [
        {
          title: 'Compte',
          name: this.routes.badge,
          link: this.links.badgeUrl || null,
          show: !!this.links.badgeUrl,
          description: this.accountDescription,
          icon: this.accountIcon,
          rank: 5,
        },
        {
          title: 'Room Service',
          name: this.routes.roomService,
          link: this.links.roomServiceUrl || null,
          show: !!this.links.roomServiceUrl,
          description: this.roomServiceDescription,
          icon: this.roomServiceIcon,
          rank: 3,
        },
        {
          title: 'Click & collect',
          name: this.routes.clickAndCollect,
          link: this.links.clickAndCollectUrl || null,
          show: !!this.links.clickAndCollectUrl,
          description: this.clickCollectDescription,
          icon: this.clickCollectIcon,
          rank: 4,
        },
      ].filter((service) => service.show);
    }
    if (
      this.enableBooking &&
      !!this.hasBookingConfig &&
      !this.services.includes(this.bookingService)
    )
      this.services.push(this.bookingService);
  }

  @Watch('$store.state.site.customerId')
  setFiles(): void {
    this.roomServiceFile();
    this.orderFile();
  }

  @Watch('$store.state.article.articles')
  showLocalService(): void {
    this.setServices();
  }

  mounted(): void {
    this.setServices();
    this.setLinks();
    this.setFiles();
  }

  setServices(): void {
    if (this.hasArticles) {
      this.services = [
        {
          title: 'Menus',
          name: this.routes.menu,
          show: true,
          description: this.menuDescription,
          icon: this.menuIcon,
          rank: 1,
        },
        {
          title: 'Actualités',
          name: this.routes.articles,
          show: this.hasArticles,
          description: this.newsDescription,
          icon: this.newsIcon,
          rank: 2,
        },
        // {
        //   title: 'Réservation',
        //   name: 'booking',
        //   show: this.isBookingActivated,
        // },
        // {
        //   title: 'Contact',
        //   name: 'contact',
        //   icon: this.contactIcon,
        //   show: this.enableContact,
        // },
      ].filter((service) => service.show);
    } else {
      this.services = [
        {
          title: 'Menus',
          name: this.routes.menu,
          show: true,
          description: this.menuDescription,
          icon: this.menuIcon,
          rank: 1,
        },
        // {
        //   title: 'Réservation',
        //   name: 'booking',
        //   show: this.isBookingActivated,
        // },
        // {
        //   title: 'Contact',
        //   name: 'contact',
        //   icon: this.contactIcon,
        //   show: this.enableContact,
        // },
      ].filter((service) => service.show);
    }
    if (
      this.enableBooking &&
      !!this.hasBookingConfig &&
      !this.services.includes(this.bookingService)
    )
      this.services.push(this.bookingService);
  }

  selectService(service: {name: string; params: {slug: string}}): void {
    if (this.$route.name !== service.name) this.$router.push(service);
  }

  onClickOutside(): void {
    this.updateOpenSelector(false);
  }

  roomServiceFile(): void {
    switch (this.customerId) {
      case 'newtime':
        this.pathRoomServiceFile = 'nt/rs.pdf';
        break;
      case 'LBP':
        this.pathRoomServiceFile = 'lbp/rs.pdf';
        break;
      case 'AQD':
        this.pathRoomServiceFile = 'aqd/rs.pdf';
        break;
      case 'HFI':
        this.pathRoomServiceFile = 'hfi/rs.pdf';
        break;
      default:
        break;
    }
  }

  orderFile(): void {
    switch (this.customerId) {
      case 'newtime':
        this.pathOrderFile = 'nt/bc.xlsx';
        break;
      case 'LBP':
        this.pathOrderFile = 'lbp/bc.xlsx';
        break;
      case 'AQD':
        this.pathOrderFile = 'aqd/bc.xlsx';
        break;
      case 'HFI':
        this.pathOrderFile = 'hfi/bc.xlsx';
        break;
      default:
        break;
    }
  }

  get accountService() {
    return {
      title: 'Mon Compte',
      description: 'Accéder à votre compte personnel',
      icon: this.svgIcons.account,
    };
  }

  get bookingService() {
    return {
      title: 'Réservation',
      description: 'Réservation de table',
      icon: this.svgIcons.tableChair,
      show: this.enableBooking && this.hasBookingConfig,
      name: this.routes.booking,
      shortName: 'Réservation',
      rank: 6,
    };
  }

  goHome(): void {
    if (
      this.$vuetify.breakpoint.smAndDown &&
      this.$route.name !== this.routes.home &&
      !this.accessToken
    ) {
      this.selectService({
        name: this.routes.home,
        params: {slug: this.$route.params.slug},
      });
    }
  }

  redirectNewUser(): void {
    if (this.isNewUser) {
      window.open(`${this.links.badgeUrl}login`, '_blank');
      this.logout(this.accessToken);
    }
  }
}
