import VComp from '@vue/composition-api';
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
} from 'chart.js';
import Vue from 'vue';
import VueMatomo from 'vue-matomo';
import Vuelidate from 'vuelidate';
import {sync} from 'vuex-router-sync';

import App from './App.vue';
import vuetify from './core/plugins/vuetify';
// import './registerServiceWorker';
import router from './core/router';
import {store} from './core/store';

Chart.register(
  BarController,
  LineController,
  BarElement,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale
);
sync(store, router);

Vue.use(Vuelidate);
Vue.use(VComp);

Vue.config.productionTip = false;
if (process.env.NODE_ENV === 'production') {
  Vue.use(VueMatomo, {
    host: 'https://impactiv.matomo.cloud',
    siteId: 7,
    router,
    requireCookieConsent: true,
  });
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
