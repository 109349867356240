import spacetime from 'spacetime';

import {formatShortDate, isBeforeDate, isEqualDate, isToday, tzParis} from '@/core/tools';

import {Day, Menu, Service} from '../interfaces';

export function reduceDays(menus: Menu[]): Day[] {
  return menus
    .sort((a, b) => (new Date(a.date) < new Date(b.date) ? -1 : 1))
    .slice(0, 5)
    .reduce<Day[]>((days, menu) => {
      if (
        !days.find((day) => isEqualDate(menu.date, day.date)) &&
        (isBeforeDate(spacetime.now(tzParis).toNativeDate().toISOString(), menu.date) ||
          isToday(menu.date))
      ) {
        days.push({date: menu.date, formattedShortDate: formatShortDate(menu.date)});
      }
      return days;
    }, [])
    // .sort((a, b) => (isBeforeDate(a.date, b.date) ? -1 : 1))
    // .slice(0, 5);
}

export function getNextDay(menus: Menu[]): Day {
  return reduceDays(menus).reduce<Day>(
    (currentDay, nextDay) =>
      isBeforeDate(currentDay.date, nextDay.date) && !isToday(currentDay.date)
        ? currentDay
        : nextDay,
    {
      date: new Date().toISOString(),
      formattedShortDate: formatShortDate(new Date().toISOString()),
    }
  );
}

export function isActivatedComment(menu: Menu | null, service: Service | null): boolean {
  if (menu && service) {
    if (isToday(menu.date)) {
      const endAt = new Date();
      const endHour = parseInt(service.endAt.split(':')[0], 10) - 1;
      endAt.setHours(
        endHour === -1 ? 23 : endHour,
        parseInt(service.endAt.split(':')[1], 10),
        0,
        0
      );
      return isBeforeDate(endAt.toISOString(), new Date().toISOString());
    }
  }
  return false;
}
