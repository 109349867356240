const brandsId: Record<string, string> = {
  demo: '496',
  newtime: '1836',
  LBP: '2401',
  AQD: '2587',
  HFI: '2794'
};

export const getBrandId = (customerId: string): string => {
  return brandsId[customerId];
};
